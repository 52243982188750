<template>
  <div class="old">
       <!-- top -->
      <el-breadcrumb separator="/">
             <el-breadcrumb-item >受检管理</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="back4">检测任务</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="back3">任务详情</el-breadcrumb-item>
            <el-breadcrumb-item>任务明细</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="boxTop">
          <div class="doBox">
              <div class="doBoxStyle" @click="download">下载团体报告</div>
              <!-- <div class="doBoxStyle">删除</div> -->
              <div class="doBoxStyle" style="width:136px !important;" @click="lookOneData">查看单条任务详情</div>
          </div>
          <span class="blueTitle">受检组织基本检测信息</span>
          <!-- base information -->
            <el-descriptions style="margin-left:40px;margin-top:40px" v-if="baseInfoData">
                <el-descriptions-item label="受检组织名称">{{baseInfoData.examinedOrgName}}</el-descriptions-item>
                <el-descriptions-item label="受检组织编号">{{baseInfoData.taskDetailNo}}</el-descriptions-item>
                <el-descriptions-item label="检测地区">{{baseInfoData.provinceName}}{{baseInfoData.cityName}}</el-descriptions-item>
                <el-descriptions-item label="详细地址">{{baseInfoData.address}}</el-descriptions-item>
                <el-descriptions-item label="检测机构">{{baseInfoData.orgName}}</el-descriptions-item>
                <el-descriptions-item label="检测时间">{{baseInfoData.examineTime}}</el-descriptions-item>
            </el-descriptions> 
      </div>
      <!--  脊柱和 足部 选择 -->
        <div class="changeBox">
            <div :class="{'bolboxl':isActive==1,'noacitve':isActive!=1}" @click="spineReport">脊柱报告</div>
            <div :class="{'bolboxl':isActive==2,'noacitve':isActive!=2}" style="margin-left:60px" @click="footReport">足部报告</div>
        </div>
        <!-- 脊柱 -->
        <div class="reportBox" v-show="isActive==1">
            <div class="boxCenter">
                <span class="blueTitle">检测情况</span>
                <!-- table -->
                <table border="1" style="width:100%;margin-top:20px" cellspacing="0" cellpadding="24" v-if="spineData">
                        <tr>
                            <td colspan="4" style="background-color:#00CCCC;color:#fff;font-size:16px">脊柱团体报告信息</td>
                        </tr>
                        <tr>
                            <td class="tdStyle">筛查总人数：{{manNormal+manAnomalous+manSeemingly+manExamineException+womanNormal+womanAnomalous+womanSeemingly+womanExamineException}}人</td>
                            <td class="tdStyle">男生人数：{{manNormal+manAnomalous+manSeemingly+manExamineException}}人</td>
                            <td class="tdStyle">女生人数：{{womanNormal+womanAnomalous+womanSeemingly+womanExamineException}}人</td>
                            <td class="tdStyle"></td>
                        </tr>
                        <tr>
                            <td class="tdStyle">正常人数：{{manNormal+womanNormal}}人</td>
                            <td class="tdStyle">姿态异常人数：{{manAnomalous+womanAnomalous}}人</td>
                            <td class="tdStyle">疑似脊柱侧弯人数：{{manSeemingly+womanSeemingly}}人</td>
                            <td class="tdStyle">检测异常人数：{{manExamineException+womanExamineException}}人</td>
                        </tr>
                    </table>
            </div>
            <div class="boxBottom">
                <div class="chartTop chartBar">
                    <span class="blueTitle" style="margin-left:24px;">筛查评估分析</span>
                    <div class="bar" ref="bar"></div>
                </div>
                <div class="chartTop chartPie">
                    <div class="pie" ref="pie"></div>
                </div>
                <div class="chartBottom">
                    <!-- title  姿态异常评估分析-->
                    <span class="blueTitle" style="margin-left:24px;">姿态异常评估分析</span>
                    <div class="chartRing">
                        <div class="ring">
                            <div class="ringAll" ref="ring1"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#33A6FF">{{manAnomalous+womanAnomalous}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormal+manAnomalous+manSeemingly+manExamineException+womanNormal+womanAnomalous+womanSeemingly+womanExamineException}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">整体姿态异常：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="ring2"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#33A6FF">{{manAnomalous}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormal+manAnomalous+manSeemingly+manExamineException+womanNormal+womanAnomalous+womanSeemingly+womanExamineException}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">男生姿态异常：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="ring3"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#33A6FF">{{womanAnomalous}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormal+manAnomalous+manSeemingly+manExamineException+womanNormal+womanAnomalous+womanSeemingly+womanExamineException}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">女生姿态异常：总人数</p>
                        </div>
                    </div>
                </div>
                <!-- 疑似脊柱侧弯 -->
                <div class="chartBottom">
                        <!-- title -->
                    <span class="blueTitle" style="margin-left:24px;">疑似脊柱侧弯评估分析</span>
                    <div class="chartRing">
                        <div class="ring">
                            <div class="ringAll" ref="hring1"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#FC5555">{{manSeemingly+womanSeemingly}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormal+manAnomalous+manSeemingly+manExamineException+womanNormal+womanAnomalous+womanSeemingly+womanExamineException}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">整体疑似脊柱侧弯：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="hring2"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#FC5555">{{manSeemingly}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormal+manAnomalous+manSeemingly+manExamineException+womanNormal+womanAnomalous+womanSeemingly+womanExamineException}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">男生疑似脊柱侧弯：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="hring3"></div>
                            
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#FC5555">{{womanSeemingly}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormal+manAnomalous+manSeemingly+manExamineException+womanNormal+womanAnomalous+womanSeemingly+womanExamineException}}</span></p>
                            <p key="font-size:14px;color:#353535">女生疑似脊柱侧弯：总人数</p>
                        </div>
                    </div>
                </div>
            </div>
      </div>

      <!-- 足部 -->
        <div class="reportBox" v-show="isActive==2">
            <div class="boxCenter">
                <span class="blueTitle">检测情况</span>
                <!-- table -->
                <table border="1" style="width:100%;margin-top:20px" cellspacing="0" cellpadding="24" v-if="spineData">
                        <tr>
                            <td colspan="5 " style="background-color:#00CCCC;color:#fff;font-size:16px">足部团体报告信息</td>
                        </tr>
                        <tr>
                            <td class="tdStyle">筛查总人数：{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}人</td>
                            <td class="tdStyle">男生人数：{{manNormalFOO+manFlat+manHigh+manCongenital+manOther}}人</td>
                            <td class="tdStyle">女生人数：{{womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}人</td>
                            <td class="tdStyle"></td>
                            <td class="tdStyle"></td>
                        </tr>
                        <tr>
                            <td class="tdStyle">正常人数：{{manNormalFOO+womanNormalFOO}}人</td>
                            <td class="tdStyle">先天畸形人数：{{manCongenital+womanCongenital}}人</td>
                            <td class="tdStyle">扁平足人数：{{manFlat+womanFlat}}人</td>
                            <td class="tdStyle">高弓足人数：{{manHigh+womanHigh}}人</td>
                            <td class="tdStyle">其他足部异常人数：{{manOther+womanOther}}人</td>
                        </tr>
                    </table>
            </div>
            <div class="boxBottom">
                <div class="chartTop chartBar">
                    <span class="blueTitle" style="margin-left:24px;">筛查评估分析</span>
                    <div class="bar" ref="barf"></div>
                </div>
                <div class="chartTop chartPie">
                    <div class="pie" ref="pief"></div>
                </div>
                <div class="chartBottom">
                    <!-- title  先天畸形评估分析-->
                    <span class="blueTitle" style="margin-left:24px;">先天畸形评估分析</span>
                    <div class="chartRing">
                        <div class="ring">
                            <div class="ringAll" ref="ring1f"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#33A6FF">{{manCongenital+womanCongenital}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">整体先天畸形数量：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="ring2f"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#33A6FF">{{manCongenital}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">男生先天畸形数量：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="ring3f"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#33A6FF">{{womanCongenital}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">女生先天畸形数量：总人数</p>
                        </div>
                    </div>
                </div>
                <!-- 扁平 -->
                <div class="chartBottom">
                        <!-- title -->
                    <span class="blueTitle" style="margin-left:24px;">扁平足评估分析</span>
                    <div class="chartRing">
                        <div class="ring">
                            <div class="ringAll" ref="hring1f"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#FC5555">{{manFlat+womanFlat}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">整体扁平足数量：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="hring2f"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#FC5555">{{manFlat}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">男生扁平足数量：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="hring3f"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#FC5555">{{womanFlat}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span></p>
                            <p key="font-size:14px;color:#353535">女生扁平足数量：总人数</p>
                        </div>
                    </div>
                </div>
                <!-- title 高弓足评估分析-->
                <div class="chartBottom">
                    <span class="blueTitle" style="margin-left:24px;">高弓足评估分析</span>
                    <div class="chartRing">
                        <div class="ring">
                            <div class="ringAll" ref="ring1fh"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#33A6FF">{{manHigh+womanHigh}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">整体高弓足数量：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="ring2fh"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#33A6FF">{{manHigh}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">男生高弓足数量：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="ring3fh"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#33A6FF">{{womanHigh}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">女生高弓足数量：总人数</p>
                        </div>
                    </div>
                </div>
                <!-- 其他足部异常 -->
                <div class="chartBottom">
                        <!-- title -->
                    <span class="blueTitle" style="margin-left:24px;">其他足部异常评估分析</span>
                    <div class="chartRing">
                        <div class="ring">
                            <div class="ringAll" ref="hring1fo"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#FC5555">{{manOther+womanOther}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">整体其他足部异常：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="hring2fo"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#FC5555">{{manOther}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span>
                            </p>
                            <p key="font-size:14px;color:#353535">男生其他足部异常：总人数</p>
                        </div>
                        <div class="ring">
                            <div class="ringAll" ref="hring3fo"></div>
                            <p style="margin-top:-50px">
                                <span style="font-size:14px;color:#FC5555">{{womanOther}}</span>
                                <span style="font-size:14px;color:#353535;opcity:.8">/{{manNormalFOO+manFlat+manHigh+manCongenital+manOther+womanNormalFOO+womanFlat+womanHigh+womanCongenital+womanOther}}</span></p>
                            <p key="font-size:14px;color:#353535">女生其他足部异常：总人数</p>
                        </div>
                    </div>
                </div>

            </div>
      </div>

  </div>
</template>

<script>
import {viewSpineOrgReport,viewFootOrgReport} from '@/api/orgController.js'
import {viewTaskInfo} from '@/api/checkTaskDetail.js'
export default {
data(){
    return{

        isActive:1,//点击切换样式
        taskDetailId:null,
        baseInfoData:null,

        spineData:null,//脊柱所有数据
            manNormal:0,//男正常
            manAnomalous:0,//男生-姿态异常
            manSeemingly:0,//男生-疑似侧弯
            manExamineException:0,//男生-检测异常
            womanNormal:0,//女正常
            womanAnomalous:0,//女生-姿态异常
            womanSeemingly:0,//女生-疑似侧弯
            womanExamineException:0,//女生-检测异常

        footData:null,//足部所有数据
            manNormalFOO:0,//男-正常
            manFlat:0,//男-扁平
            manHigh:0,//男-高弓足
            manCongenital:0,//男-先天畸形
            manOther:0,//男-其他足部异常
            womanNormalFOO:0,
            womanFlat:0,
            womanHigh:0,
            womanCongenital:0,
            womanOther:0
    }
},
created(){
    if(this.$route.query.id){
        this.taskDetailId= this.$route.query.id;
        
        this.getBaseInfo(this.taskDetailId);
        this.getSpineData(this.taskDetailId);//test --'1534954382317268994'
        this.getFootData(this.taskDetailId);
    }
},


methods:{
    //面包屑事件
    back3(){
        this.$router.go(-1);
    },
    back4(){
        this.$router.go(-2);
    },
    //单条任务详情
    lookOneData(){
        this.$router.push(
            {
                path:'/checkTask/taskDetails/sigleTaskList',
                query:{
                    reporttype:'PRO',
                    taskDetailId:this.taskDetailId
                }
            })
    },
    //download
    download(){
       this.$router.push({
        path:'/downloadReport',
        query:{
                taskDetailId:this.taskDetailId
            }
        })
    },
    //切换为脊柱报告
    spineReport(){
        this.isActive=1;
    },
    //切换为足部报告
    footReport(){
        this.isActive=2;
        setTimeout(()=>{
            this.barChartf();
            this.pieChartf();
            this.ringChart1f();
            this.ringChart2f();
            this.ringChart3f();
            this.hringChart1f();
            this.hringChart2f();
            this.hringChart3f();
            //高弓足
            this.ringChart1fh();
            this.ringChart2fh();
            this.ringChart3fh();
          //其它足部异常
            this.hringChart1fo();
            this.hringChart2fo();
            this.hringChart3fo();
        },500)
    },
    //getBaseInfo-data
    getBaseInfo(id){
        viewTaskInfo({
            id:id
        }).then(res=>{
            if(res.data.status==200){
                this.baseInfoData = res.data.data;
            }else{
                this.$message.error(res.data.data.message);
            }
        }).catch(res=>{
            this.$message.error(res);
        })
    },
    //getData--spine
    getSpineData(id){
        viewSpineOrgReport({
            id:id
        }).then(res=>{
            if(res.data.status==200){
              
                this.spineData = res.data.data;
                if(res.data.data.manNormal!=null){
                    this.manNormal = res.data.data.manNormal
                }
                if(res.data.data.manAnomalous!=null){
                    this.manAnomalous = res.data.data.manAnomalous
                }
                if(res.data.data.manSeemingly!=null){
                    this.manSeemingly = res.data.data.manSeemingly
                }
                if(res.data.data.manExamineException!=null){
                    this.manExamineException = res.data.data.manExamineException
                }
                //wonman
                 if(res.data.data.womanNormal!=null){
                    this.womanNormal = res.data.data.womanNormal
                }
                if(res.data.data.womanAnomalous!=null){
                    this.womanAnomalous = res.data.data.womanAnomalous
                }
                if(res.data.data.womanSeemingly!=null){
                    this.womanSeemingly = res.data.data.womanSeemingly
                }
                if(res.data.data.womanExamineException!=null){
                    this.womanExamineException = res.data.data.womanExamineException
                }
                  
                //渲染图表
                    this.barChart();
                    this.pieChart();
                    this.ringChart1();
                    this.ringChart2();
                    this.ringChart3();
                    this.hringChart1();
                    this.hringChart2();
                    this.hringChart3();
            }else{
                this.$message.error(res.data.data.message);
            }
        }).catch(res=>{
            this.$message.error(res);
        })
    },
    //getData--foot
    getFootData(id){
        viewFootOrgReport({
            id:id
        }).then(res=>{
            if(res.data.status==200){
                
                this.footData = res.data.data;
                //
                if(res.data.data.manNormal!=null){
                    this.manNormalFOO = res.data.data.manNormal;
                }
                if(res.data.data.manFlat!=null){
                    this.manFlat = res.data.data.manFlat;
                }
                if(res.data.data.manHigh!=null){
                    this.manHigh = res.data.data.manHigh;
                }
                if(res.data.data.manCongenital!=null){
                    this.manCongenital = res.data.data.manCongenital;
                }
                if(res.data.data.manOther!=null){
                    this.manOther = res.data.data.manOther;
                }
               
                if(res.data.data.womanNormal!=null){
                    this.womanNormalFOO = res.data.data.womanNormal;
                }
                if(res.data.data.womanFlat!=null){
                    this.womanFlat = res.data.data.womanFlat;
                }
                if(res.data.data.womanHigh!=null){
                    this.womanHigh = res.data.data.womanHigh;
                }
                if(res.data.data.womanCongenital!=null){
                    this.womanCongenital = res.data.data.womanCongenital;
                }
                if(res.data.data.womanOther!=null){
                    this.womanOther = res.data.data.womanOther;
                }
        

            }else{
                this.$message.error(res.data.data.message);
            }
        }).catch(res=>{
            this.$message.error(res);
        })
    },

    //charts methods --spine
    barChart(){
        let bar1 = this.$echarts.init(this.$refs.bar);
        let option = {
             title:{
                    text:"有效总人数脊柱占比数据图",
                 },
            textStyle:{
                fontSize:'16px',
                color:'#353535'
            },

            tooltip: {
                trigger: 'item',
                formatter:"{b}:{c}人",
            },
            legend: {
                top: '8%',
                left: 'left'
            },
            series: [
                {
                type: 'pie',
                radius: ['40%', '70%'],
                center:['36%','60%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    formatter:'{d}%' ,
                    labelLine :{show:true} 
                },
               itemStyle:{
                   normal:{
                        color: function(params) {
                            // build a color map as your need.
                            var colorList = ['#00CCCC','#33A6FF','#FC5555','#FFE74E' ];
                                return colorList[params.dataIndex]
                            },
                        },
               },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: '20',
                    fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: this.manNormal+this.womanNormal, name: '正常' },
                    { value: this.manAnomalous+this.womanAnomalous, name: '姿态异常' },
                    { value: this.manSeemingly+this.womanSeemingly, name: '疑似脊柱侧弯' },
                    { value: this.manSeemingly+this.womanSeemingly, name: '检测异常' },
                ]
            }]
        };
        bar1.setOption(option);
        
    },
    //pieChart
    pieChart(){
        let pie1 = this.$echarts.init(this.$refs.pie);
        let option ={
                 title: {
                        text: '有效男女脊柱分类占比数据图'
                    },
                textStyle:{
                    fontSize:'16px',
                    color:'#353535'
                    },
            tooltip: {
                trigger: 'axis',
                formatter: function(params) {
						var relVal = params[0].name;
						var value = 0;//总量
						for (var i = 0, l = params.length; i < l; i++) {
							value += params[i].value;
						};
						for (var i = 0, l = params.length; i < l; i++) {
							//marker 就是带颜色的小圆圈 seriesName图例名称；  value y轴坐标名称；
							relVal += '<br/>'+ params[i].marker + params[i].seriesName + parseFloat(params[i].value) +'人' +" "
                            +' 占比比例 : ' + (100 *parseFloat(params[i].value) / parseFloat(value)).toFixed(0) + "%";
						}
						return relVal;
					},
            },
            legend: {
                top: '8%',
                left: 'left',
                icon: "circle",
            },
            grid: {
                left: '2%',
                top:'20%',
                right: '16%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.6],
                axisLabel:{
                    show:true
                },
            },
            yAxis: {
                type: 'category',
                data: ['检测异常','疑似脊柱侧弯','姿态异常','正常'],
                axisLabel:{
                    show:true,
                },
                axisTick:{
                    show :false
                },
            },
            series: [
                {
                name: '男',
                type: 'bar',
                data: [this.manExamineException,this.manSeemingly,this.manAnomalous,this.manNormal],
                 itemStyle:{
                   normal:{color:'#33A6FF' }
                },
                 label:{
                        show: true,
                        position: 'right',
                        formatter: '{c}人',
                    }
                },
                {
                name: '女',
                type: 'bar',
                 itemStyle:{
                   normal:{color:'#00CCCC' }
                },
                data: [this.womanExamineException,this.womanSeemingly,this.womanAnomalous,this.womanNormal],
                 label:{
                        show: true,
                        position: 'right',
                        formatter: '{c}人',
                    }
                },
            ]
        };
        pie1.setOption(option);
       
    },
    
    //ring1 
    ringChart1(){
        let ring1 = this.$echarts.init(this.$refs.ring1);
        const gaugeData =[
            {
                value: ((this.manAnomalous+this.womanAnomalous)/(this.manNormal+this.manAnomalous+this.manSeemingly+this.manExamineException+this.womanNormal+this.womanAnomalous+this.womanSeemingly+this.womanExamineException)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#33A6FF',
                        borderColor: '#33A6FF'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#33A6FF',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        ring1.setOption(option);
       

    },
    //ring2
    ringChart2(){
        let ring2 = this.$echarts.init(this.$refs.ring2);
        const gaugeData =[
            {
                value: ((this.manAnomalous)/(this.manNormal+this.manAnomalous+this.manSeemingly+this.manExamineException+this.womanNormal+this.womanAnomalous+this.womanSeemingly+this.womanExamineException)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#33A6FF',
                        borderColor: '#33A6FF'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#33A6FF',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        ring2.setOption(option);
    },
    //ring3
    ringChart3(){
        let ring3 = this.$echarts.init(this.$refs.ring3);
        const gaugeData =[
            {
                value: ((this.womanAnomalous)/(this.manNormal+this.manAnomalous+this.manSeemingly+this.manExamineException+this.womanNormal+this.womanAnomalous+this.womanSeemingly+this.womanExamineException)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#33A6FF',
                        borderColor: '#33A6FF'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#33A6FF',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                  }
                ]
        };
        ring3.setOption(option);
        
    },
    //hring
    //ring1 
    hringChart1(){
        let hring1 = this.$echarts.init(this.$refs.hring1);
        const gaugeData =[
            {
                value:((this.manSeemingly+this.womanSeemingly)/(this.manNormal+this.manAnomalous+this.manSeemingly+this.manExamineException+this.womanNormal+this.womanAnomalous+this.womanSeemingly+this.womanExamineException)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#FC5555',
                        borderColor: '#FC5555'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#FC5555',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        hring1.setOption(option);
       
    },
    //ring2
    hringChart2(){
        let hring2 = this.$echarts.init(this.$refs.hring2);
        const gaugeData =[
            {
                value:((this.manSeemingly)/(this.manNormal+this.manAnomalous+this.manSeemingly+this.manExamineException+this.womanNormal+this.womanAnomalous+this.womanSeemingly+this.womanExamineException)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#FC5555',
                        borderColor: '#FC5555'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#FC5555',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        hring2.setOption(option);
        
    },
    //ring3
    hringChart3(){
        let hring3 = this.$echarts.init(this.$refs.hring3);
        const gaugeData =[
            {
                value:((this.womanSeemingly)/(this.manNormal+this.manAnomalous+this.manSeemingly+this.manExamineException+this.womanNormal+this.womanAnomalous+this.womanSeemingly+this.womanExamineException)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#FC5555',
                        borderColor: '#FC5555'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#FC5555',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                  }
                ]
        };
        hring3.setOption(option);
    },
    
    //charts methods --foot
    barChartf(){
        let bar1 = this.$echarts.init(this.$refs.barf);
        let option = {
             title:{
                    text:"有效总人数足部占比数据图",
                 },
            textStyle:{
                fontSize:'16px',
                color:'#353535'
            },

            tooltip: {
                trigger: 'item',
                formatter:"{b}:{c}人",
            },
            legend: {
                top: '8%',
                left: 'left'
            },
            series: [
                {
                type: 'pie',
                radius: ['40%', '70%'],
                center:['36%','60%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                    formatter:'{d}%' ,
                    labelLine :{show:true} 
                },
               itemStyle:{
                   normal:{
                        color: function(params) {
                            // build a color map as your need.
                            var colorList = ['#00CCCC','#33A6FF','#FC5555','#FFE74E','#99ff33' ];
                                return colorList[params.dataIndex]
                            },
                        },
               },
                emphasis: {
                    label: {
                    show: true,
                    fontSize: '20',
                    fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: this.manNormalFOO+this.womanNormalFOO, name: '正常' },
                    { value: this.manCongenital+this.womanCongenital, name: '先天畸形' },
                    { value: this.manFlat+this.womanFlat, name: '扁平' },
                    { value: this.manHigh+this.womanHigh, name: '高弓足' },
                    { value: this.manOther+this.womanOther, name: '其他足部异常' },
                ]
            }]
        };
        bar1.setOption(option);
        
    },
    //pieChart
    pieChartf(){
        let pie1 = this.$echarts.init(this.$refs.pief);
        let option ={
                 title: {
                        text: '有效男女足部分类占比数据图'
                    },
                textStyle:{
                    fontSize:'16px',
                    color:'#353535'
                    },
            tooltip: {
                trigger: 'axis',
                formatter: function(params) {
						var relVal = params[0].name;
						var value = 0;//总量
						for (var i = 0, l = params.length; i < l; i++) {
							value += params[i].value;
						};
						for (var i = 0, l = params.length; i < l; i++) {
							//marker 就是带颜色的小圆圈 seriesName图例名称；  value y轴坐标名称；
							relVal += '<br/>'+ params[i].marker + params[i].seriesName + parseFloat(params[i].value) +'人' +" "
                            +' 占比比例 : ' + (100 *parseFloat(params[i].value) / parseFloat(value)).toFixed(0) + "%";
						}
						return relVal;
					},
            },
            legend: {
                top: '8%',
                left: 'left',
                icon: "circle",
            },
            grid: {
                left: '2%',
                top:'20%',
                right: '16%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.6],
                axisLabel:{
                    show:true
                },
            },
            yAxis: {
                type: 'category',
                data: ['其他足部异常','高弓足','扁平','先天畸形','正常'],
                axisLabel:{
                    show:true,
                },
                axisTick:{
                    show :false
                },
            },
            series: [
                {
                name: '男',
                type: 'bar',
                data: [this.manOther,this.manHigh,this.manFlat,this.manCongenital,this.manNormalFOO],
                 itemStyle:{
                   normal:{color:'#33A6FF' }
                },
                 label:{
                        show: true,
                        position: 'right',
                        formatter: '{c}人',
                    }
                },
                {
                name: '女',
                type: 'bar',
                 itemStyle:{
                   normal:{color:'#00CCCC' }
                },
                data: [this.womanOther,this.womanHigh,this.womanFlat,this.womanCongenital,this.womanNormalFOO],
                 label:{
                        show: true,
                        position: 'right',
                        formatter: '{c}人',
                    }
                },
            ]
        };
        pie1.setOption(option);
       
    },
    
    //ring1 --先天畸形  ddsds 
    ringChart1f(){
        let ring1 = this.$echarts.init(this.$refs.ring1f);
        const gaugeData =[
            {
                value: ((this.manCongenital+this.womanCongenital)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#33A6FF',
                        borderColor: '#33A6FF'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#33A6FF',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        ring1.setOption(option);
       
    },
    //ring2
    ringChart2f(){
        let ring2 = this.$echarts.init(this.$refs.ring2f);
        const gaugeData =[
            {
                value:((this.manCongenital)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#33A6FF',
                        borderColor: '#33A6FF'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#33A6FF',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        ring2.setOption(option);
    },
    //ring3
    ringChart3f(){
        let ring3 = this.$echarts.init(this.$refs.ring3f);
        const gaugeData =[
            {
                value:((this.womanCongenital)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#33A6FF',
                        borderColor: '#33A6FF'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#33A6FF',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                  }
                ]
        };
        ring3.setOption(option);
    },
    //hring ---扁平
    //ring1 
    hringChart1f(){
        let hring1 = this.$echarts.init(this.$refs.hring1f);
        const gaugeData =[
            {
                value:((this.manFlat+this.womanFlat)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#FC5555',
                        borderColor: '#FC5555'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#FC5555',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        hring1.setOption(option);
       
    },
    //ring2
    hringChart2f(){
        let hring2 = this.$echarts.init(this.$refs.hring2f);
        const gaugeData =[
            {
                value:((this.manFlat)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#FC5555',
                        borderColor: '#FC5555'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#FC5555',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        hring2.setOption(option);
    },
    //ring3
    hringChart3f(){
        let hring3 = this.$echarts.init(this.$refs.hring3f);
        const gaugeData =[
            {
                value:((this.womanFlat)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#FC5555',
                        borderColor: '#FC5555'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#FC5555',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                  }
                ]
        };
        hring3.setOption(option);
    },
    //高弓足
    //ring1h 
    ringChart1fh(){
        let ring1 = this.$echarts.init(this.$refs.ring1fh);
        const gaugeData =[
            {
                value:((this.manHigh+this.womanHigh)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#FFE74E',
                        borderColor: '#FFE74E'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#FFE74E',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        ring1.setOption(option);
       
    },
    //ring2h
    ringChart2fh(){
        let ring2 = this.$echarts.init(this.$refs.ring2fh);
        const gaugeData =[
            {
                value:((this.manHigh)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#FFE74E',
                        borderColor: '#FFE74E'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#FFE74E',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        ring2.setOption(option);
    },
    //ring3
    ringChart3fh(){
        let ring3 = this.$echarts.init(this.$refs.ring3fh);
        const gaugeData =[
            {
                value:((this.womanHigh)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#FFE74E',
                        borderColor: '#FFE74E'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#FFE74E',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                  }
                ]
        };
        ring3.setOption(option);
    },
    //其它足部异常
    //ring1 
    hringChart1fo(){
        let hring1 = this.$echarts.init(this.$refs.hring1fo);
        const gaugeData =[
            {
                value:((this.manOther+this.womanOther)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#99ff33',
                        borderColor: '#99ff33'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#99ff33',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        hring1.setOption(option);
       
    },
    //ring2
    hringChart2fo(){
        let hring2 = this.$echarts.init(this.$refs.hring2fo);
        const gaugeData =[
            {
                value:((this.manOther)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#99ff33',
                        borderColor: '#99ff33'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#99ff33',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                    }
                ]
        };
        hring2.setOption(option);
    },
    //ring3
    hringChart3fo(){
        let hring3 = this.$echarts.init(this.$refs.hring3fo);
        const gaugeData =[
            {
                value:((this.womanOther)/(this.manNormalFOO+this.manFlat+this.manHigh+this.manCongenital+this.manOther+this.womanNormalFOO+this.womanFlat+this.womanHigh+this.womanCongenital+this.womanOther)*100).toFixed(2),//data
                detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%']
                }
            }
        ];
        let option ={
               series: [
                    {
                    type: 'gauge',
                    startAngle: 90,
                    endAngle: -270,
                    pointer: {
                        show: false
                    },
                    center:['50%','56%'],
                    progress: {
                        show: true,
                        overlap: false,
                        roundCap: true,
                        clip: false,
                        itemStyle: {
                        borderWidth: 1,
                        color:'#99ff33',
                        borderColor: '#99ff33'
                        }
                    },
                    axisLine: {
                        lineStyle: {
                        width: 10
                        }
                    },
                    splitLine: {
                        show:false,
                        distance: 0,
                        length: 10
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        distance: 50
                    },
                    data: gaugeData,
                    title: {
                        fontSize: 14
                    },
                    detail: {
                        width: 0,
                        height: 0,
                        fontSize: 14,
                        color: '#99ff33',
                        borderRadius: 0,
                        borderWidth: 0,
                        formatter: '{value}%'
                    }
                  }
                ]
        };
        hring3.setOption(option);
    },
}
}
</script>

<style lang="stylus" scoped>
//change element-ui style
@import '~@/assets/css/orgListDetail.css'

//echart normal style
.chartRing
    width: 100%
    height: 380px
    display: flex
    justify-content: space-evenly
    .ring
        width: 30%
        height:300px
        text-align: center
        .ringAll
            width: 100%
            height: 300px
.noacitve 
    width: 68px  
    font-size:16px
    color: #353535
    margin-left: 20px
    cursor pointer
    text-align: center
    opacity .4

//nolmal style
.blueTitle
    font-size: 16px 
    color: #353535
    line-height: 28px 
    font-weight: bold
    margin-left: 10px
    position: relative
    &::before
        width: 3px
        height: 18px
        content: ""
        position: absolute
        border-radius: .03rem
        left: -10px
        bottom:1px
        background-color: #00CCCC
.tdStyle
    font-size: 14px 
    color: #858585
// normal style
.old 
    overflow hidden
    .boxTop 
        overflow hidden
        height: 340px
        margin-top: 16px
        padding: 20px 20px 0 20px
        background-color: #FFFFFF
        .doBox
            display: flex
            justify-content: space-between
            margin-bottom: 60px
            .doBoxStyle
                width: 116px
                height: 32px 
                background-color: #00CCCC
                line-height: 32px 
                color: #fff
                text-align: center
                border-radius: 2px 
                margin-right: 16px 
                cursor pointer
    .changeBox
        width: 100%
        height: 30px
        padding-top:20px
        margin-top: 24px
        background-color: #fff
        display:flex
        .bolboxl
            width: 68px  
            font-size:16px
            color: #353535
            margin-left: 20px
            cursor pointer
            text-align: center
            position: relative 
            &::after 
                content: '' 
                position: absolute 
                width: 100% !important
                left 0 !important
                bottom: 6px 
                height: 4px 
                border-radius: 5px
                background-color:#00CCCC!important
    .reportBox
        overflow hidden
        .boxCenter
            overflow hidden
            height: 280px
            padding: 20px 20px 0 20px
            background-color: #FFFFFF
        .boxBottom
            overflow hidden
            margin-top: 24px
            padding: 20px 0px 0 0px
            display: flex
            justify-content: space-between
            flex-wrap: wrap
            .chartTop
                width: 48%
                height:608px 
                overflow hidden
                padding-top: 24px
                background-color: #fff
            .chartBottom
                width: 48%
                margin-top: 24px
                padding-top: 24px
                background-color: #fff 
            .bar  
                width: 100%
                margin-left: 24px
                margin-top: 24px
                height:460px
            .pie 
                width: 100%
                margin-left: 24px
                margin-top: 48px
                height:460px 


               
                
</style>>
